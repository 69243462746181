<template>
  <base-layout>
  <profile-detail></profile-detail>
  </base-layout>
</template>

<script>
import ProfileDetail from '../components/employe/ProfileDetail.vue';
export default {
  name: "perfil-page",
  components: {ProfileDetail},
};
</script>

<style>
</style>