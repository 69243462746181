<template>
  <ion-list class="ion-padding" lines="none">
      <ion-item>
          <ion-avatar size="large">
              <ion-img src="/assets/icon/favicon.png"></ion-img>
          </ion-avatar>
      </ion-item>
      <ion-item>
          <ion-label position="stacked">Nombres</ion-label>
          <ion-input readonly v-model="user.name"/>
      </ion-item>
      <ion-item>
          <ion-label position="stacked">Apellidos</ion-label>
          <ion-input readonly>Pedro</ion-input>
      </ion-item>
      <ion-item>
          <ion-label position="stacked">Edad</ion-label>
          <ion-input readonly>18</ion-input>
      </ion-item>
      <ion-item>
          <ion-label position="stacked">RH</ion-label>
          <ion-input readonly>O+</ion-input>
      </ion-item>
      <ion-item>
          <ion-label position="stacked">Dirección</ion-label>
          <ion-input readonly>Cl 1 No 15 29</ion-input>
      </ion-item>
  </ion-list>
</template>

<script>
import { IonList, IonItem, IonLabel, IonInput, IonAvatar, IonImg} from '@ionic/vue';
import { mapGetters } from 'vuex';
export default {
    name: 'profile-detail',
    components:{IonList, IonItem, IonLabel, IonInput, IonAvatar, IonImg},
    data(){
        return{
            
        }
    },
    mounted() {
        
    },
    computed:{
        ...mapGetters('user',['user']),
    },
    methods:{
       
    }
}
</script>

<style>

</style>